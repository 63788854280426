'use strict';
app.factory('uomDataSourceService', ['$http', '$rootScope', function ($http, $rootScope) {

    var uomDataSourceServiceFactory = {};

    var _getUOMDataSource = function () {
        var dataSource = new kendo.data.DataSource({
            type: "odata",
            transport: {
                read:
                    function (options) {
                        $http({
                            url: $rootScope.masterOdataUrl + "UOM?$format=json",
                            method: 'GET',
                            jsonp: false,
                            dataType: "json"
                        })
                        .success(function (result) {
                            options.success(result);
                        });
                    }


            },
            schema: {
                data: function (data) {
                    return data["value"];
                },
                total: function (data) {
                    return data["odata.count"];
                },
                model: {
                    id: "UOMKey",
                    fields: {
                        UOMKey: { type: "int", editable: false, nullable: false, defaultValue: 0 },
                        ClientKey: { type: "int", editable: false, nullable: false, defaultValue: 0 },
                        OUKey: { type: "int", editable: false, nullable: false },
                        UOMCode: { type: "string", editable: true, nullable: false },
                        UOMDesc: { type: "string", editable: true, nullable: false },
                        Active: { type: "boolean", editable: true, nullable: false },
                        RcdType: { type: "int", editable: false, nullable: false },
                        RcdTypeDesc: { type: "string", editable: false, nullable: false },
                        CreatedBy: { type: "int", editable: false, nullable: false },
                        CreatedDate: { type: "string", editable: false, nullable: false },
                        UpdateBy: { type: "int", editable: false, nullable: false },
                        UpdatedDate: { type: "string", editable: false, nullable: false },
                    }
                }
            }
        });
        return dataSource;
    };

    uomDataSourceServiceFactory.getUOMDataSource = _getUOMDataSource;

    return uomDataSourceServiceFactory;

}]);